import breakpoints from 'constants/breakpoints';

import styled from 'styled-components';

type ContainerProps = {
    large: boolean;
};

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 100%;
    width: 100%;
    padding: 0 20px;
    margin: ${({large}) => (large ? '96px' : '100px')} auto;

    .large-section-title,
    .section-title {
        margin-bottom: 25px;
    }

    ${breakpoints.abovePhone} {
        padding: 0;
        max-width: ${({large}) => (large ? '650px' : '550px')};
    }

    ${breakpoints.aboveTablet} {
        max-width: ${({large}) => (large ? '900px' : '650px')};
    }
`;
