import styled from 'styled-components';

import breakpoints from '~constants/breakpoints';

export const Anchor = styled.div`
    position: absolute;
    top: -75px;

    ${breakpoints.aboveTablet} {
        top: -150px;
    }
`;
