import React from 'react';

import {Container} from './styles';

type TextContainerProps = {
    children: React.ReactNode;
    size?: 'large' | 'small';
};
const TextContainer: React.FC<TextContainerProps> = ({children, size = 'large'}) => {
    return <Container large={size === 'large'}>{children}</Container>;
};

export default TextContainer;
