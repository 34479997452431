import {getImageData} from '~utils/imageData';
import {Anchor} from '~atoms/AbsoluteAnchor';
import {PageQueryPageDataAttributesContentComponentSectionDome} from '~interfaces/graphql/PageQuery';
import Text from '~components/atoms/Text';
import TextContainer from '~components/atoms/TextContainer';
import Block from '~components/molecules/Block';

import {BlocksContainer, Container, Row, SphereContainer} from './styles';

type DomeProps = {
    data: PageQueryPageDataAttributesContentComponentSectionDome;
};

const DomeSection = ({
    data: {sectionId, sectionName, sectionTitle, description, blocks},
}: DomeProps) => {
    return (
        <Container>
            <Anchor id={sectionId} />
            <Row>
                <TextContainer>
                    <Text type="sectionName" color="pink">
                        {sectionName}
                    </Text>
                    <Text type="sectionTitle">{sectionTitle}</Text>
                    <Text type="content">{description}</Text>
                </TextContainer>
                <SphereContainer />
            </Row>
            <BlocksContainer>
                {blocks.map((item, index) => (
                    <Block
                        key={`hero_block_${index}`}
                        {...item}
                        icon={getImageData(item.icon)}
                    />
                ))}
            </BlocksContainer>
        </Container>
    );
};

export default DomeSection;
