import React from 'react';
import Image from 'next/image';

import {ImageDataType} from '~utils/imageData';
import Text from '~components/atoms/Text';

import {Column, Container, IconContainer, ImageBeforeContainer} from './styles';

export type BlockProps = {
    title: string;
    description: string;
    subtitle?: string;
    icon?: ImageDataType;
    imageBefore?: ImageDataType;
};

const Block: React.FC<BlockProps> = ({
    title,
    subtitle,
    description,
    icon,
    imageBefore,
}) => {
    return (
        <Container>
            <Column>
                {icon && (
                    <IconContainer>
                        <Image {...icon} width={32} height={32} layout="fixed" />
                    </IconContainer>
                )}
                {imageBefore && (
                    <ImageBeforeContainer>
                        <Image
                            {...imageBefore}
                            layout="fill"
                            objectFit="contain"
                            objectPosition="left"
                        />
                    </ImageBeforeContainer>
                )}
                <Text type="sectionName" color="white">
                    {title}
                </Text>
                {subtitle && (
                    <Text type="content" color="purple" bold>
                        {subtitle}
                    </Text>
                )}
                <Text type="content">{description}</Text>
            </Column>
        </Container>
    );
};

export default Block;
