import breakpoints from 'constants/breakpoints';

import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: rgba(87, 78, 160, 0.15);
    border-radius: 24px;
    padding: 30px;

    p {
        opacity: 0.85;

        &.feature {
            background: url('/assets/icons/list-circle.svg') no-repeat left center;
            padding-left: 16px;
        }
    }

    ${breakpoints.aboveDesktop} {
        padding: 60px;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

export const IconContainer = styled.div`
    display: flex;
    margin-right: 15px;
    margin-bottom: 10px;
`;

export const ImageBeforeContainer = styled.div`
    position: absolute;
    height: 100px;
    width: 100%;
    top: 0;
    left: 30px;
    transform: translateY(-50%);

    ${breakpoints.aboveDesktop} {
        height: 150px;
        left: 60px;
    }
`;
