import breakpoints from 'constants/breakpoints';

import styled from 'styled-components';

import {Container as TextContainer} from '~components/atoms/TextContainer/styles';
import {Container as Block} from '~components/molecules/Block/styles';

export const Container = styled.div`
    margin: 0 0 150px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        background: url('/assets/bg/dome-bg-mobile.svg') no-repeat center;
        width: 100vw;
        background-size: contain;
        aspect-ratio: 375 / 1467;
        z-index: -1;
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        background: url('/assets/bg/dome-lines-mobile.svg') no-repeat center;
        height: 110%;
        aspect-ratio: 193 / 808;
        background-size: contain;
        z-index: -1;
    }

    ${breakpoints.abovePhone} {
        padding: 0 50px;

        &::after {
            height: 750px;
        }

        &::before {
            background: url('/assets/bg/dome-bg.svg') no-repeat center;
            aspect-ratio: 1440 / 1143;
            background-size: contain;
        }
    }

    ${breakpoints.aboveTablet} {
        padding: 0 100px;
        margin: 160px 0 55px;

        &::after {
            height: 1300px;
            background: url('/assets/bg/dome-lines.svg') no-repeat center;
            aspect-ratio: 420 / 1259;
        }
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;

    ${TextContainer} {
        align-items: flex-start;
        text-align: left;

        ${breakpoints.phone} {
            margin-top: 50px;
            margin-bottom: 20px;
        }
    }

    ${breakpoints.abovePhone} {
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 50px;

        ${TextContainer} {
            max-width: 500px;
            margin: 0;
        }
    }
`;

export const SphereContainer = styled.div`
    width: 200px;
    height: 200px;
    position: relative;
    margin-right: auto;
    margin-bottom: 20px;

    ${breakpoints.abovePhone} {
        margin: 0;
        margin-left: 50px;
        width: 250px;
        height: 250px;
    }

    ${breakpoints.aboveTablet} {
        margin-left: 100px;
        width: 300px;
        height: 300px;
    }

    &::after {
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        background: url('/assets/bg/sphere.svg') center no-repeat;
        background-size: contain;
        z-index: 2;
        left: -30px;

        ${breakpoints.aboveTablet} {
            left: unset;
            top: 75px;
        }
    }
`;

export const BlocksContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, max-content));
    grid-gap: 50px;
    width: 100%;
    padding: 0 20px;

    justify-content: center;
    align-items: center;
    position: relative;

    ${breakpoints.aboveTablet} {
        grid-gap: 25px;
        padding: 0;
        justify-content: space-between;
    }

    ${Block} {
        padding: 30px;
        margin: 0;
        background: rgba(0, 3, 27, 0.2);
        backdrop-filter: blur(10px);

        p {
            padding-left: 0;
            background: none;
            font-size: 14px;
        }

        .section-name {
            font-size: 18px;
        }
    }
`;
